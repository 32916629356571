@import url('https://fonts.googleapis.com/css?family=Comfortaa:400,700');

.App {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Comfortaa', cursive;

}

a {
    text-decoration: none;
    color: black;
}

.dropzone {
    cursor: pointer;
    background: white;
    border-radius: 5px;
    border: 2px dashed rgb(0, 135, 247);
    border-image: none;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: 120px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.selectApp {
    padding: 10px;
    width: 200px;
    margin-bottom: 20px;
    border: 1px solid rgba(10, 10, 10, 0.2);
}

.AppSelecter_Block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 25px;
}

.AppSelector_Item {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    border-radius: 100%;

    cursor: pointer;

    position: relative;

    overflow: hidden;

    transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -webkit-transition: all .15s ease-out;

    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;

    border: 1px solid rgba(10, 10, 10, 0.3);

    margin: 15px;

    line-height: 18px;
}

.AppSelector_Item_Smaller {
    width: 40px;
    height: 40pX;
}

.AppSelector_Item:hover {
    opacity: 0.8;

    -ms-transform: scale(1.1, 1.1); /* IE 9 */
    -webkit-transform: scale(1.1, 1.1); /* Safari */
    transform: scale(1.1, 1.1);
}

.AppSelector_Item_Bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba(45, 47, 56, 1);
}

.AppSelector_Item_Text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.AppSelector_Item_Sel .AppSelector_Item_Bg {
    opacity: 1;
}

.AppSelector_Item_Sel .AppSelector_Item_Text {
    color: white;
}

.AppSelector_Item_UnSel .AppSelector_Item_Bg {
    opacity: 0.05;
}

.textInputLine {
    padding: 15px;
    margin-top: 15px;
    width: 95%;
    max-width: 500px;
    border: 1px solid rgba(10, 10, 10, 0.2);
}

.textInput {
    margin-top: 15px;
    height: 150px;
    width: 95%;
    max-width: 500px;
    border: 1px solid rgba(10, 10, 10, 0.2);
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
    background-color: rgb(0, 135, 247);
    margin: 25px;
    cursor: pointer;
    color: white;
    font-size: 13px;
    border-radius: 3px;

    transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -webkit-transition: all .15s ease-out;
}

.button:hover {
    background-color: rgba(0, 135, 247, 0.7);
}

.buttonClear {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
    background-color: rgb(100, 100, 100);
    margin: 25px;
    cursor: pointer;
    color: white;
    font-size: 13px;
    border-radius: 3px;

    transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -webkit-transition: all .15s ease-out;
}

.buttonClear:hover {
    background-color: rgba(200, 0, 0, 0.7);
}

#WorkArea {
    width: 100%;
}

.animatingOpacity {
    animation: OpacityAnimationDO 1s infinite;

}

@-webkit-keyframes OpacityAnimationDO {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.4;
    }
}

@keyframes OpacityAnimationDO {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.4;
    }
}

.spinner2 {
    width: 40px;
    height: 40px;

    position: relative;
    margin: 0px auto;
}

.spinner {
    width: 40px;
    height: 40px;

    position: relative;
    margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #479eb4;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0)
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

/*
.setColor_rimedi_naturali
{
    background-color: #884d07;
    color:white;
    border: 1px solid #884d07;
}


.setColor_puzzle
{
    background-color: #0d467b;
    color:white;
    border: 1px solid #0d467b;
}


.setColor_tv
{
    background-color: #ff9212;
    color:white;
    border: 1px solid #ff9212;}

.setColor_ricette
{
    background-color: #ff4e00;
    color:white;
    border: 1px solid #ff4e00;

}*/

.InputClassMulti {
    padding: 15px;
    color: #4d5970;
    border: 1px solid #b2b2b2;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
}

.InputClassMulti2 {
    padding: 15px;
    color: #4d5970;
    border: 1px solid #b2b2b2;
    font-size: 15px;
    line-height: 23px;
    text-align: left;
    font-weight: bold;
}

.CharCounter {
    padding: 15px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    background-color: #475059;
    color: white;
    border-radius: 5px;
}

.DropZoneStyleGeneric {
    width: 80%;
    height: 90%;
    border: 1px solid rgba(10, 10, 10, 0.2);
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DropZoneStyle {
    width: 150px;
    height: 120px;
    border: 1px solid rgba(10, 10, 10, 0.2);
    padding: 5px;
    margin: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DropZoneStyleEditor {
    width: 100%;
    height: 220px;
    border: 1px solid rgba(10, 10, 10, 0.2);
    padding: 5px;
    margin: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DropZoneStyleMulti {
    width: 90%;
    height: 150px;
    border: 1px solid rgba(10, 10, 10, 0.2);

    margin: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DropZoneStyleInner {
    position: relative;

    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 50% 50%;

    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.RichEditor-editor {
    width: 100%;
    cursor: text;
    font-size: 16px;
    border: 1px solid rgba(10, 10, 10, 0.1);
}

.RichEditorEditorHighligted {
    border: 1px solid #29a5ed;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    padding: 15px;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;

}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Open Sans', sans-serif;

    font-size: 16px;
    padding: 20px;
}

.RichEditor-controls {
    font-family: 'Open Sans', sans-serif;

    font-size: 14px;
    user-select: none;
    width: 100%;
    text-align: left;
    border: 1px solid rgba(10, 10, 10, 0.1);
}

.RichEditor-styleButton {
    padding: 10px;
    color: #999;
    cursor: pointer;
    margin-right: 16px;

    display: inline-block;
}

.RichEditor-activeButton {
    color: #5890ff;
}

.genericInput {
    margin-bottom: 25px;
    padding: 15px;
    width: 100%;
    max-width: 800px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

.tag input, .tag-editor, .tag-editor-placeholder, .tag-editor-mirror {
    font-size: 1rem;
    width: 100%;
}

.tag input, .tag-editor-placeholder {
    padding: 1px 2em 1px 0.6em;
    line-height: 1.8;
}

.tag {
    display: inline-block;
    margin-right: .3em;
    margin-bottom: .5em;
    position: relative;
}

.tag input {
    margin: 0;
    border: 0;
    box-sizing: content-box;
    background-color: #32a9ee;
    color: white;
    min-width: 1em;
    border-radius: 7px;
}

.tag input:focus {
    color: #111;
    background-color: rgba(50, 160, 250, 0.1);
    border: 1px solid rgba(50, 160, 250, 0.5);
    outline: 0;
}

.tag input:focus + a {
    display: none;
}

.tag a {
    position: absolute;
    top: 50%;
    right: 0.3em;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    color: #222;
    line-height: 1;
    font-size: 1.231em;
    background: #fff;
    width: 1em;
    height: 1em;
    text-align: center;
}

.tag a:hover {
    color: #111;
}

.tag.has-error input {
    background-color: #f2c9c9;
}

.tag.has-error input,
.tag.has-error a {
    transition: color 1s;
    color: #5c0909;
}

.tag-editor {
    border-radius: 7px;
    padding: .5em .5em 0;
    border: 1px solid #ccc;
    cursor: text;
}

.tag-editor.is-active {
    border-color: #29a5ed;
    border-radius: 7px;
}

.tag-editor-placeholder {
    color: #777;
    margin-bottom: .5em;
}

.tag-editor-mirror {
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: auto;
    visibility: hidden;
}

/* Example Styles for React Tags*/
div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
    outline: none;

}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;

}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    color: white;
    border: 1px solid #ddd;
    background-color: #29a5ed;
    border-radius: 12px;
    font-size: 14px;
    display: inline-block;
    padding: 7px;
    margin: 0 5px;
}

div.ReactTags__selected a.ReactTags__remove {
    color: white;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}

.roundBEditor {
    border: 1px solid rgba(10, 10, 10, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #18a1e1;
    padding: 15px;
    border-radius: 100%;
    cursor: pointer;
    color: white;
    font-size: 12px;
    font-weight: bold;
    margin: 15px;
    width: 30px;
    height: 30px;
}

.roundBEditorToPublish {
    background-color: #404a4e;

}

.roundBEditorToUnPublish {
    background-color: red;

}

.counterButton {
    background-color: #305e71;

}

.roundButtonDiv {
    cursor: pointer;
    margin: 15px;
    width: 70px;
    height: 70px;
    background-color: rgba(27, 73, 108, 0.5);
    color: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.roundButtonDivSel {
    cursor: pointer;
    margin: 15px;
    width: 70px;
    height: 70px;
    background-color: #000000;
    color: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.socialgage_tips_cat {
    padding: 9px;
    border-radius: 15px;
    border: 1px solid rgba(10, 10, 10, 0.2);
    margin: 10px;
    background-color: #2b6673;
    color: white;
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
}

.roundButtonQueueEditor {
    width: 50px;
    height: 50px;
    padding: 9px;
    border-radius: 100%;
    border: 1px solid rgba(10, 10, 10, 0.1);
    margin: 10px;
    background-color: rgba(50, 58, 51, 0.2);
    color: rgba(10, 10, 10, 0.7);
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.roundButtonQueueEditorSelected {
    background-color: #27aedb;
    color: white;
}

.roundButtonQueueEditorHighlit {
    background-color: red;
    color: white;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}





.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
